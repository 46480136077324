<script lang="ts">
	import iconFetcher from 'src/lib/icon-fetcher';
	import { CountToolsDoneOfSectionByTeam } from 'src/graphql-operations';
	import type { GetSectionsBasicQuery } from 'src/graphql-operations';
	import userStore from 'src/stores/user';
	import Spinner from 'src/components/common/spinner.svelte';
	import SectionProgressCircularBar from './section-progress-circular-bar.svelte';
	import { page } from '$app/stores';

	const { project: projectId } = $page.params;

	export let section: GetSectionsBasicQuery['sections'][0],
		orientation: 'ROW' | 'COL' = 'ROW';

	const toolsDone = CountToolsDoneOfSectionByTeam({
		variables: { section_id: section.id, team_id: projectId || userStore.getSelectedProjectId() }
	});

	const colors: { [slug: typeof section['slug']]: { bg: string; text: string } } = {
		think: {
			bg: 'text-think-500',
			text: 'text-think-700'
		},
		experience: {
			bg: 'text-experience-500',
			text: 'text-experience-500'
		},
		manage: {
			bg: 'text-manage-500',
			text: 'text-manage-500'
		}
	};
</script>

{#if orientation == 'ROW'}
	<!-- content here -->
	<a href="/methodology?section={section.slug}">
		<div
			class="rounded-[30px] section-progress-card p-5 relative w-full h-full
	       cursor-pointer hover:-translate-y-1 transition-all"
		>
			<div class="flex self-start flex-row absolute top-5 left-5 items-center">
				{#await iconFetcher.getContentIconPath('section', section.id, 'medium') then iconPath}
					<img src={iconPath} alt={section.name} class="h-[62px] w-[62px]" />
				{/await}
				<span class="font-semibold text-2xl {colors[section.slug].text}">
					{section.name}
				</span>
			</div>

			<div class="self-end absolute bottom-5 left-5 {colors[section.slug].text}">
				{#if $toolsDone.loading}
					<Spinner size="1.5rem" color="{section.slug}-500" />
				{:else if $toolsDone.data}
					<p class="font-semibold text-2xl">
						{$toolsDone.data.done.aggregate.count} de {$toolsDone.data.all.aggregate.count}
					</p>
				{/if}
				<p class="font-semibold text-sm">Ferramentas Concluídas</p>
			</div>

			{#if !$toolsDone.loading}
				<div class="absolute right-5 bottom-5">
					<SectionProgressCircularBar
						colors={colors[section.slug]}
						doneCount={$toolsDone.data.done.aggregate.count}
						allCount={$toolsDone.data.all.aggregate.count}
					/>
				</div>
			{/if}
		</div>
	</a>
{:else}
	<div class="flex flex-col items-center justify-center space-y-5">
		<div>
			{#await iconFetcher.getContentIconPath('section', section.id, 'medium') then iconPath}
				<img src={iconPath} alt={section.name} class="h-[62px] w-[62px] mx-auto" />
			{/await}
			<span class="font-semibold text-2xl {colors[section.slug].text}">
				{section.name}
			</span>
		</div>
		{#if !$toolsDone.loading}
			<div class="w-full">
				<SectionProgressCircularBar
					colors={colors[section.slug]}
					doneCount={$toolsDone.data.done.aggregate.count}
					allCount={$toolsDone.data.all.aggregate.count}
				/>
			</div>
		{/if}
		<div class={colors[section.slug].text}>
			{#if $toolsDone.loading}
				<Spinner size="1.5rem" color="{section.slug}-500" />
			{:else if $toolsDone.data}
				<p class="font-semibold text-2xl text-center">
					{$toolsDone.data.done.aggregate.count} de {$toolsDone.data.all.aggregate.count}
				</p>
			{/if}
			<p class="font-bold text-xs">Ferramentas Concluídas</p>
		</div>
	</div>
{/if}

<style>
	.section-progress-card {
		background: linear-gradient(
			264.5deg,
			rgba(255, 255, 255, 0.5) 6.2%,
			rgba(255, 255, 255, 0.265) 100.99%
		);
		box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.1), inset 2px 6px 27px rgba(0, 0, 0, 0.1);
	}
</style>
