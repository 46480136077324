<script>
	import { tweened } from 'svelte/motion';
	import { circOut } from 'svelte/easing';

	export let doneCount, allCount, colors;
	let pct = tweened(0, {
		duration: 1000,
		delay: 500,
		easing: circOut
	});

	$: pct.set((doneCount / allCount) * 100);
</script>

<div class="flex justify-center items-center">
	<div class="h-32 w-32 md:w-40 md:h-40 lg:h-32 lg:w-32 xl:h-40 xl:w-40 absolute overflow-visible">
		<svg viewBox="0 0 100 100" class="overflow-visible">
			{#if $pct == 100}
				<circle
					cx="50"
					cy="50"
					r="46"
					fill="none"
					stroke="currentColor"
					stroke-width="20"
					class={colors.bg}
				/>
			{:else if $pct > 4}
				<circle
					cx="50"
					cy="50"
					r="46"
					fill="none"
					stroke="currentColor"
					stroke-width="12"
					stroke-dasharray="{($pct * 289) / 100},1000"
					stroke-dashoffset="0"
					transform="rotate(-90) translate(-100)"
					class={colors.bg}
				/>

				<circle
					cx="50"
					cy="50"
					r="52"
					fill="none"
					stroke="currentColor"
					stroke-linecap="round"
					stroke-width="8"
					stroke-dasharray="{($pct * 327) / 100 - 8},1000"
					stroke-dashoffset="-4"
					transform="rotate(-90) translate(-100)"
					class={colors.bg}
				/>

				<circle
					cx="50"
					cy="50"
					r="40"
					fill="none"
					stroke="currentColor"
					stroke-linecap="round"
					stroke-width="8"
					stroke-dasharray="{($pct * 251.3) / 100 - 8},1000"
					stroke-dashoffset="-4"
					transform="rotate(-90) translate(-100)"
					class={colors.bg}
				/>
			{/if}
		</svg>
	</div>
	<div
		class="border-[12px] border-neutral-100 rounded-full h-32 w-32 md:w-40 md:h-40 lg:h-32 lg:w-32 xl:h-40 xl:w-40 flex justify-center items-center"
	>
		<p class="font-semibold text-4xl {colors.text}">
			{$pct.toFixed(0)}%
		</p>
	</div>
</div>
